.pages-hotDetail {
  padding: 150px 0 40px;
  margin: 0 auto;
  overflow: hidden;
  width: 755px;
  background-color: #FFFFFF;
}
.pages-hotDetail .bread-crumb {
  font-size: 16px;
  color: #000000;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.pages-hotDetail .bread-crumb .splitter {
  padding: 0 5px;
  opacity: 0.45;
  margin-bottom: 3px;
}
.pages-hotDetail .bread-crumb .news-details {
  opacity: 0.45;
}
.pages-hotDetail h4 {
  padding: 0 0 16px 0;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0;
  text-align: left;
  font-weight: normal;
}
.pages-hotDetail .essay-time {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
}
.pages-hotDetail .hotDetail-content {
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
}
.pages-hotDetail .hotDetail-content p {
  line-height: 20px;
}
.pages-hotDetail .hotDetail-content p span {
  font-size: 14px;
}
@media (max-width: 992px) {
  .pages-hotDetail .hotDetail-content p span {
    font-size: 12px;
  }
}
.pages-hotDetail .essay-source {
  float: right;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  letter-spacing: 0;
  text-align: left;
  padding-top: 50px;
}
.pages-hotDetail .image-wrap img {
  display: inline-block;
}
@media (max-width: 992px) {
  .pages-hotDetail .image-wrap img {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .pages-hotDetail .hotDetail-content {
    padding-top: 14px;
  }
  .pages-hotDetail .hotDetail-img {
    width: 745px;
    height: 503px;
    background-color: #999999;
    margin: 0 auto;
  }
  .pages-hotDetail .hotDetail-img img {
    margin: 21px 0 25px 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .pages-hotDetail {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
  }
  .pages-hotDetail .hotDetail-content {
    padding-top: 14px;
  }
  .pages-hotDetail .hotDetail-img {
    width: 745px;
    height: 503px;
    background-color: #999999;
    margin: 0 auto;
  }
  .pages-hotDetail .hotDetail-img img {
    margin: 21px 0 25px 0;
  }
}
@media (max-width: 767px) {
  .pages-hotDetail {
    padding: 60px 0 40px;
    width: 85%;
    margin: 0 auto;
    overflow: hidden;
  }
  .pages-hotDetail .pages-hotDetail h4 {
    padding: 0 0 9px 0;
    letter-spacing: 0;
    text-align: left;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
  }
  .pages-hotDetail .hotDetail-content {
    font-size: 14px;
    color: #000000;
    opacity: 0.85;
  }
  .pages-hotDetail .essay-time {
    display: none;
  }
  .pages-hotDetail .hotDetail-img {
    width: 100px;
    height: 100px;
    background-color: #999999;
    margin: 0 auto;
  }
  .pages-hotDetail .hotDetail-img img {
    margin: 21px 0 25px 0;
  }
}
