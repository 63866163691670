.pages-login * {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.pages-login body {
  padding: 0;
}
.pages-login .top {
  padding-top: 16vh;
  background-image: url('//img3.dian.so/lhc/2019/04/22/3840w_1200h_0BE0C1555916758.jpg');
  background-size: cover;
  background-position: center;
}
.pages-login .login-page {
  height: 84vh;
  margin: 0 auto;
}
.pages-login .login-page .login-accept {
  flex: 1;
  margin-left: 16px;
  color: #777777;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.pages-login .login-page input[type='checkbox'] {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  font-size: 14px;
  margin-right: 4px;
}
.pages-login .login-page input[type='checkbox']::after {
  position: absolute;
  top: 0;
  width: 15px;
  height: 15px;
  display: inline-block;
  visibility: visible;
  padding-left: 0;
  text-align: center;
  content: ' ';
  border-radius: 3px;
}
.pages-login .login-page .login-accept a {
  color: #777777;
}
.pages-login .login-container {
  width: 50%;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(210, 210, 210, 0.77);
  border-radius: 8px;
  padding: 36px;
  position: relative;
}
.pages-login .login-container .login-close {
  position: absolute;
  color: #999999;
  right: 20px;
  top: 10px;
  transform: rotate(45deg);
  font-size: 30px;
  cursor: pointer;
}
.pages-login .login-container .login-top {
  width: 50%;
  margin: 0 auto;
}
.pages-login .login-container .login-form {
  margin: 60px auto 0 auto;
  width: 86%;
}
.pages-login .login-container .login-form .login-form-tip {
  height: 60px;
  font-size: 14px;
  color: #ee3636;
  padding-left: 88px;
}
.pages-login .login-container .login-form .login-form-item {
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: 100%;
}
.pages-login .login-container .login-form .login-form-item .label {
  display: inline-block;
  width: 60px;
  color: #26C441;
  font-size: 18px;
  text-align: right;
}
.pages-login .login-container .login-form .login-form-item .input {
  flex: 1;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  padding: 12px 18px;
  margin-left: 16px;
}
.pages-login .login-container .login-form .login-form-item .input:focus {
  outline: none;
  border: 1px solid #26C441;
}
.pages-login .login-container .login-btns {
  display: flex;
}
.pages-login .login-container .login-btn {
  flex: 1;
  height: 46px;
  line-height: 46px;
  color: #FFFFFF;
  font-size: 20px;
  text-align: center;
  background: #26C441;
  margin: 0 5%;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .pages-login .top {
    background-image: url('//img3.dian.so/lhc/2019/04/22/3840w_1200h_0BE0C1555916758.jpg');
    background-size: cover;
    background-position: center;
  }
  .pages-login .login-page {
    height: calc(100vh - 50px);
  }
  .pages-login .login-container {
    width: 260px;
    padding: 16px;
  }
  .pages-login .login-container .login-top {
    margin-top: 10px;
  }
  .pages-login .login-container .login-close {
    right: 10px;
    top: 5px;
  }
  .pages-login .login-container .login-form {
    margin-top: 30px;
  }
  .pages-login .login-container .login-form .login-form-item .label {
    font-size: 14px;
  }
  .pages-login .login-container .login-form .login-form-item .input {
    padding: 8px;
    margin-left: 8px;
  }
  .pages-login .login-container .login-form .login-form-tip {
    height: 40px;
    font-size: 12px;
    padding-left: 54px;
  }
  .pages-login .login-container .login-btn {
    height: 36px;
    line-height: 36px;
    font-size: 15px;
  }
}
