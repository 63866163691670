.pages-joinUs {
  color: #FFFFFF;
}
.pages-joinUs .title {
  font-size: 30px;
  margin-bottom: 68px;
}
.pages-joinUs .sub-title {
  font-size: 24px;
  margin-bottom: 20px;
}
.pages-joinUs .swiper-head-container {
  width: 100%;
}
.pages-joinUs .swiper-head-container .img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.pages-joinUs .joinUs {
  width: 800px;
  color: #000000;
}
.pages-joinUs .joinUs .join-us-box {
  padding-top: 65px;
}
.pages-joinUs .joinUs img {
  width: 100%;
}
.pages-joinUs .joinUs .font-desc {
  margin-bottom: 152px;
  color: #FFFFFF;
}
.pages-joinUs .joinUs .join-bottom-pc {
  display: flex;
  align-items: baseline;
}
.pages-joinUs .joinUs .join-bottom {
  background-color: #FFFFFF;
  height: 290px;
  margin-bottom: 65px;
}
.pages-joinUs .joinUs .join-bottom .join-title {
  font-size: 28px;
  height: 140px;
  line-height: 140px;
}
.pages-joinUs .joinUs .join-bottom .left {
  width: 40%;
  text-align: center;
}
.pages-joinUs .joinUs .join-bottom .left .btn {
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 36px;
  font-size: 13px;
  padding: 12px 24px;
  cursor: pointer;
}
.pages-joinUs .introduce-box {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 110px;
}
.pages-joinUs .introduce-box img {
  margin-bottom: 280px;
}
.pages-joinUs .introduce-box .btn {
  background-color: #FFFFFF;
  color: #000000;
  font-size: 13px;
  border: 2px solid #000000;
  border-radius: 36px;
  padding: 12px 24px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .pages-joinUs .title {
    font-size: 24px;
  }
  .pages-joinUs .sub-title {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
  }
  .pages-joinUs .swiper-head-container .img {
    height: 668px;
    object-fit: cover;
  }
  .pages-joinUs .joinUs {
    width: 100%;
    padding: 0 !important;
  }
  .pages-joinUs .joinUs .font-desc {
    padding: 0 46px;
    font-size: 12px;
    color: #BBBFCA;
    line-height: 20px;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .pages-joinUs .joinUs .join-us-box .top-img {
    width: 90%;
    margin: auto;
  }
  .pages-joinUs .joinUs .join-us-box .join-title {
    display: none;
  }
  .pages-joinUs .joinUs .join-us-box .join-desc-box {
    flex-flow: column nowrap;
  }
  .pages-joinUs .joinUs .join-us-box .join-bottom {
    padding: 60px;
    padding-bottom: 0;
    margin-bottom: 56px;
  }
  .pages-joinUs .joinUs .join-us-box .join-bottom .join-title-desc {
    font-size: 12px;
    text-align: center;
    margin-bottom: 80px;
  }
  .pages-joinUs .joinUs .join-us-box .join-bottom .join-desc-box-xs .left {
    width: 100%;
    text-align: center;
  }
  .pages-joinUs .joinUs .join-us-box .join-bottom .join-desc-box-xs .left .btn {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 36px;
    font-size: 12px;
    padding: 12px 20px;
    cursor: pointer;
  }
  .pages-joinUs .joinUs .introduce-box img {
    padding-bottom: 50px;
    width: 280px;
    margin: auto;
  }
  .pages-joinUs .joinUs .introduce-box .title {
    margin-bottom: 26px;
  }
}
