.pages-agent .head-active > a {
  color: #26C441 !important;
}
.pages-agent .head-child-active > a {
  background-color: #26C441 !important;
  color: #FFFFFF !important;
}
.pages-agent .top {
  padding: 111px 0 73px;
  background-image: url('//img3.dian.so/lhc/2019/04/22/3840w_1200h_0BE0C1555916758.jpg');
  background-size: cover;
  background-position: center;
}
.pages-agent .top a {
  width: 270px;
  height: 55px;
  line-height: 55px;
  color: #FFFFFF;
  font-size: 24px;
  text-align: center;
  background: #26C441;
  margin: 44px 0 0;
  border-radius: 4px;
}
.pages-agent .top .banner-right {
  width: 406px;
}
.pages-agent .top .banner-left {
  text-align: left;
  color: #FFFFFF;
  padding-right: 16px;
}
.pages-agent .top .top-banner {
  display: flex;
  justify-content: space-between;
}
.pages-agent .top h1 {
  font-size: 36px;
  line-height: 45px;
  color: #FFFFFF;
  margin: 0 0 34px;
  padding-top: 58px;
}
.pages-agent .top h6 {
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
}
.pages-agent .panel1 {
  text-align: center;
  color: #000000;
  width: 100%;
  padding: 80px 20px 0;
  background-color: #FFFFFF;
}
.pages-agent .panel1 .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 50px;
}
.pages-agent .panel1 img {
  width: 80%;
  margin: 0 auto;
}
.pages-agent .panel2 {
  text-align: center;
  color: #000000;
  width: 100%;
  padding: 80px 20px 80px;
  background-color: #FFFFFF;
}
.pages-agent .panel2 .title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 50px;
}
.pages-agent .panel2 img {
  width: 90%;
  margin: 0 auto;
}
.pages-agent .bottom {
  color: #000000;
  text-align: center;
  background-color: #EBF7ED;
  padding: 37px 0;
}
.pages-agent .bottom > p.title {
  display: block;
  font-size: 30px;
  font-weight: bold;
}
.pages-agent .bottom > p.subtitle {
  font-size: 16px;
  display: block;
  padding: 20px 0 50px;
}
.pages-agent .bottom > a {
  text-align: center;
  width: 267px;
  height: 55px;
  line-height: 55px;
  color: #FFFFFF;
  font-size: 24px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #26C441;
}
@media (max-width: 767px) {
  .pages-agent .head-child-active > a {
    background-color: #FFFFFF !important;
    color: #26C441 !important;
  }
  .pages-agent .top {
    margin-top: 44px;
    padding: 25px;
    background-image: url('//img3.dian.so/lhc/2019/04/22/3840w_1200h_0BE0C1555916758.jpg');
    background-size: cover;
    background-position: center;
  }
  .pages-agent .top a {
    width: 108px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    margin-top: 18px;
  }
  .pages-agent .top .banner-right {
    width: 135px;
    height: 136px;
  }
  .pages-agent .top h1 {
    font-size: 13px;
    line-height: 17px;
    color: #FFFFFF;
    margin: 0 0 9px;
    padding-top: 14px;
  }
  .pages-agent .top h6 {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
  }
  .pages-agent .panel1 {
    text-align: center;
    color: #000000;
    width: 100%;
    padding: 40px 10px 0;
    background-color: #FFFFFF;
  }
  .pages-agent .panel1 .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 25px;
  }
  .pages-agent .panel1 img {
    width: 100%;
    margin: 0 auto;
  }
  .pages-agent .panel2 {
    text-align: center;
    color: #000000;
    width: 100%;
    padding: 40px 10px 40px;
    background-color: #FFFFFF;
  }
  .pages-agent .panel2 .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 25px;
  }
  .pages-agent .panel2 img {
    width: 90%;
    margin: 0 auto;
  }
  .pages-agent .bottom {
    color: #FFFFFF;
    text-align: center;
    background-color: #26C441;
    padding: 19px 0;
  }
  .pages-agent .bottom > p.title {
    display: block;
    font-size: 20px;
    font-weight: bold;
  }
  .pages-agent .bottom > p.subtitle {
    font-size: 14px;
    display: block;
    padding: 10px 0 25px;
  }
  .pages-agent .bottom > a {
    text-align: center;
    width: 130px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .pages-agent .top > img {
    width: 400px;
  }
  .pages-agent .top > a {
    width: 250px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
  }
  .pages-agent .panel1 {
    padding: 80px 0 0;
  }
  .pages-agent .panel1 .title {
    font-size: 34px;
  }
  .pages-agent .panel2 .title {
    font-size: 34px;
  }
  .pages-agent .bottom > p.title {
    font-size: 34px;
  }
  .pages-agent .bottom > a {
    width: 230px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
  }
}
