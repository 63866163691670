.pages-cooperation-form {
  background: #FFFFFF;
  padding-top: 44px;
  padding-right: 56px;
  padding-left: 56px;
  padding-bottom: 52px;
}
.pages-cooperation-form .address-cascader {
  flex: 1;
}
.pages-cooperation-form .address-cascader .ant-select-selector {
  border: 0;
}
.pages-cooperation-form .MuiGrid-item:nth-child(4) .MuiInput-underline::before {
  border-width: 0;
}
.pages-cooperation-form .MuiGrid-item:nth-child(4) .MuiInputBase-inputMarginDense {
  padding-top: 9px;
}
.pages-cooperation-form .MuiFormControlLabel-labelPlacementStart {
  border-bottom: 1px solid #D8D8D8;
  margin-top: 20px;
  width: 100%;
  margin-left: 0;
}
.pages-cooperation-form .MuiFormLabel-asterisk {
  color: #FF951C;
}
.pages-cooperation-form .MuiInputLabel-asterisk {
  color: #FF951C;
}
.pages-cooperation-form .MuiFormLabel-root {
  color: #A1A1A1;
  font-size: 12px;
}
.pages-cooperation-form .MuiFormControl-marginDense {
  width: 100%;
}
.pages-cooperation-form .MuiInput-underline::before {
  border-bottom: 1px solid #D8D8D8;
}
.pages-cooperation-form .back-btn {
  width: 16px;
  position: relative;
  top: 28px;
  left: -16px;
}
.pages-cooperation-form .back-btn::before,
.pages-cooperation-form .back-btn::after {
  position: absolute;
  content: ' ';
  background-color: #D8D8D8;
  height: 2px;
}
.pages-cooperation-form .back-btn::before {
  width: 16px;
}
.pages-cooperation-form .back-btn::after {
  width: 5px;
  transform: rotate(-45deg) translate(2px, -1px);
}
.pages-cooperation-form .merchant-form-title {
  font-size: 20px;
  line-height: 28px;
  margin-top: 75px;
  margin-bottom: 26px;
  color: #3A3B3B;
  text-align: center;
  font-weight: bolder;
}
.pages-cooperation-form .btn {
  font-size: 16px;
  color: #FFFFFF;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: #4ACA6C;
  cursor: pointer;
  margin-top: 50px;
  font-weight: bolder;
  border: 0;
}
.pages-cooperation-form .loading {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.pages-cooperation-form ::-webkit-input-placeholder {
  font-size: 12px;
  color: #D4D4D4;
}
.pages-cooperation-form .MuiGrid-item:last-child .MuiInput-underline::before {
  border-width: 0;
}
.pages-cooperation-form .MuiGrid-item:last-child .MuiFormControl-root {
  padding-left: 10px;
  border: 1px solid #D4D4D4;
  border-radius: 6px;
  min-height: 56px;
  margin-top: 25px;
}
.pages-cooperation-form .MuiGrid-item:last-child .MuiInput-underline::after {
  display: none;
}
