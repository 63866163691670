.component-pagination .pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.component-pagination .pagination > ul {
  display: inline-block;
  padding-top: 30px;
}
.component-pagination .pagination > ul .left {
  background: transparent;
}
.component-pagination .pagination > ul .right {
  background: transparent;
}
.component-pagination .pagination > ul > li {
  width: 26px;
  height: 26px;
  margin: 0 4px;
  padding: 9px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  border-radius: 16px;
  line-height: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #BEC2CD;
}
.component-pagination .pagination > ul > li.disabled {
  opacity: 0.38;
  cursor: default;
}
.component-pagination .pagination > ul > li img {
  width: 6px;
}
.component-pagination .pagination > ul .active {
  background: #26C441;
}
