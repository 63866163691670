.pages-agentRecruit .container-box-xs .btn-box {
  width: 100%;
  position: absolute;
  bottom: 80px;
  text-align: center;
}
.pages-agentRecruit .container-box-xs .btn-box .btn {
  border: 1px solid #54E67B;
  font-size: 14px;
  color: #54E67B;
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
}
.pages-agentRecruit .container-box-xs .title {
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  text-align: center;
}
.pages-agentRecruit .container-box-xs .top {
  position: relative;
}
.pages-agentRecruit .container-box-xs .top img {
  width: 100%;
}
.pages-agentRecruit .container-box-xs .top .font {
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 1.62px;
  margin-top: 45px;
}
.pages-agentRecruit .container-box-xs .top > a {
  width: 106px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.81px;
  margin-top: 21px;
  display: block;
  padding: 6px 13px;
  background: #26C441;
  border-radius: 4px;
}
.pages-agentRecruit .container-box-xs .xiaodian-powerbank {
  margin-top: 45px;
}
.pages-agentRecruit .container-box-xs .xiaodian-powerbank .img {
  width: 60%;
  margin: 0 auto;
  margin-top: 40px;
}
.pages-agentRecruit .container-box-xs .xiaodian-powerbank .img-xs {
  margin-bottom: 150px;
  object-fit: cover;
}
.pages-agentRecruit .container-box-xs .xiaodian-group-img .img {
  width: 100%;
}
.pages-agentRecruit .container-box-xs .panel1-xs {
  padding-bottom: 50px;
  height: 100%;
  background-color: #F7F7F8;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form {
  min-width: 408px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .place-input {
  margin: -8px auto -8px;
  text-align: center;
  white-space: nowrap;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .place-input .place {
  width: 90px;
  position: relative;
  top: 34px;
  left: 16px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .place-input:nth-child(2) .place {
  left: 10px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .ant-select-selector {
  border: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile {
  background: #FFFFFF;
  border-radius: 6px;
  width: 240px;
  height: 41px;
  text-indent: 14px;
  font-size: 10px;
  letter-spacing: 0.68px;
  margin: 5px auto;
  margin-top: 15px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-selector {
  height: 40px;
  line-height: 41px;
  padding: 0;
  border: 1px solid #D7D9DD;
  border-radius: 6px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-selection-search-input {
  height: 39px;
  border: 0;
  width: auto;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-selection-placeholder {
  line-height: 39px;
  color: #B2B2B2;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-arrow {
  position: absolute;
  right: 10px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-arrow .anticon {
  position: absolute;
  right: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .anticon-close-circle {
  position: absolute;
  right: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .address-cascader-mobile .ant-select-selection-item {
  line-height: 39px;
  color: #333333;
  font-weight: bolder;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .item_input {
  background: #FFFFFF;
  border: 1px solid #D7D9DD;
  border-radius: 6px;
  width: 240px;
  height: 41px;
  text-indent: 14px;
  font-size: 10px;
  letter-spacing: 0.68px;
  margin: 5px auto;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .item_input:nth-child(5) {
  margin-top: 13px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .item_input::-webkit-input-placeholder {
  font-size: 12px;
  color: #B8B8B8;
  letter-spacing: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .item_input::-moz-placeholder {
  font-size: 12px;
  color: #B8B8B8;
  letter-spacing: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .item_input::-ms-input-placeholder {
  font-size: 12px;
  color: #B8B8B8;
  letter-spacing: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .submitBtn {
  padding: 16px 27px;
  background: #26C441;
  border-radius: 25px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.95px;
  cursor: pointer;
  margin: 28px auto 10px;
  text-align: center;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .form-bottom {
  width: 100%;
  font-size: 8px;
  color: #e8e8e8;
  letter-spacing: 0.44px;
  text-align: center;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .form-bottom-phone {
  text-align: center;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .form-bottom-phone .phone {
  font-size: 14px;
  color: #5D5E61;
  letter-spacing: 0;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .form-bottom-phone .phone .icon-bohao {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  display: inline-block;
  background-image: url('https://img3.dian.so/lhc/2021/10/19/34w_34h_82C771634630143.png');
  background-size: cover;
  background-position: center;
  margin-right: 11px;
}
.pages-agentRecruit .container-box-xs .panel1-xs .box .form .form-bottom-phone .time {
  font-size: 10px;
  color: #939599;
  letter-spacing: 0;
  margin-top: 3px;
}
.pages-agentRecruit .container-box-xs .select-xiaodian {
  background-image: url('https://img3.dian.so/lhc/2021/09/15/750w_272h_40D541631668522.jpg');
  background-size: cover;
  background-position: center;
  padding: 42px 0;
}
.pages-agentRecruit .container-box-xs .select-xiaodian .font {
  font-size: 20px;
  color: #26C441;
  font-weight: bold;
  letter-spacing: 1.11px;
  text-align: center;
  line-height: 26px;
}
.pages-agentRecruit .container-box-xs .fit-situation {
  height: 100%;
  background-image: url('https://img3.dian.so/lhc/2021/09/15/752w_1080h_F19341631668696.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pages-agentRecruit .container-box-xs .fit-situation .img {
  width: 100%;
  padding: 46px 72px;
}
.pages-agentRecruit .container-box-xs .company-safe {
  height: 100%;
  background-image: url('https://img3.dian.so/lhc/2021/09/15/750w_1000h_73E401631669456.jpg');
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pages-agentRecruit .container-box-xs .company-safe .img {
  width: 100%;
  padding: 60px 58px;
}
.pages-agentRecruit .container-box-xs .help-operate {
  height: 100%;
  background-image: url('https://img3.dian.so/lhc/2021/09/15/752w_1080h_153DC1631669949.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pages-agentRecruit .container-box-xs .help-operate .img {
  width: 100%;
  padding: 83px 0;
}
.pages-agentRecruit .container-box-xs .nation-company {
  padding: 56px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pages-agentRecruit .container-box-xs .nation-company .img {
  width: 100%;
  padding: 0 23px;
}
.pages-agentRecruit .container-box .title {
  font-size: 44px;
  line-height: 70px;
  color: #FFFFFF;
  text-align: center;
}
.pages-agentRecruit .container-box .top > img {
  margin-top: 100px;
  width: 100%;
}
.pages-agentRecruit .container-box .top .img {
  margin-bottom: 40px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  background-image: url('https://img3.dian.so/lhc/2022/08/31/1920w_450h_837831661929932.png');
}
.pages-agentRecruit .container-box .xiaodian-power-bank {
  margin-bottom: 620px;
}
.pages-agentRecruit .container-box .xiaodian-power-bank .title {
  margin-bottom: 70px;
}
.pages-agentRecruit .container-box .xiaodian-power-bank > img {
  width: 340px;
  margin: 0 auto;
}
.pages-agentRecruit .container-box .form {
  position: relative;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 21%, #FFFFFF 100%);
  min-height: 400px;
  padding-bottom: 40px;
  margin-bottom: 160px;
}
.pages-agentRecruit .container-box .form > img {
  width: 748px;
  margin: 0 auto;
  position: absolute;
  top: -420px;
  left: 50%;
  transform: translateX(-50%);
}
.pages-agentRecruit .container-box .xiaodian-select .img1 {
  margin: 0 auto;
  margin-top: 130px;
  margin-bottom: 50px;
  width: 980px;
}
.pages-agentRecruit .container-box .xiaodian-select .img2 {
  margin: 200px auto 530px;
  width: 891px;
}
