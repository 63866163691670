.total-box {
  position: absolute;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.total-box .total-content {
  top: -100px;
  width: 222px;
  height: 143px;
  padding-top: 31px;
  border-radius: 7px;
  opacity: 0.6;
  color: #FFFFFF;
  text-align: center;
  background: #6B6B6B;
  margin: 0 auto;
  position: relative;
}
.total-box .total-content .success {
  width: 8px;
  height: 16px;
  margin: 0 auto;
  border-color: #FFFFFF;
  border-style: solid;
  border-width: 0 3px 5px 0;
  transform: rotate(45deg);
}
.total-box .total-content .close {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #6B6B6B;
  position: absolute;
  margin: 0 auto;
  top: 160px;
  left: calc(50% - 11px);
}
.total-box .total-content .close::before,
.total-box .total-content .close::after {
  position: absolute;
  content: ' ';
  background-color: #E7E7E7;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 10px;
}
.total-box .total-content .close::before {
  transform: rotate(45deg);
}
.total-box .total-content .close::after {
  transform: rotate(-45deg);
}
