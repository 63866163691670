.form-index {
  width: 700px;
  margin: 0 auto;
}
.form-index .address-cascader-pc {
  flex: 1;
  border: 0;
}
.form-index .ant-select-selector {
  border: 0 !important;
}
.form-index .MuiFormControlLabel-labelPlacementStart {
  border-bottom: 1px solid #A1A1A1;
  margin-top: 20px;
  margin-left: 0;
  width: 90%;
}
.form-index .MuiFormLabel-asterisk,
.form-index .MuiInputLabel-asterisk {
  color: red;
}
.form-index .MuiFormLabel-root {
  color: #A1A1A1;
  font-size: 12px;
}
.form-index .MuiInput-underline::before {
  border-bottom: 1px solid #A1A1A1;
}
.form-index .MuiTextField-root {
  width: 90%;
  margin-bottom: 30px;
}
.form-index .action-box {
  display: flex;
}
.form-index .action-box .left {
  width: 50%;
  position: relative;
}
.form-index .action-box .left img {
  width: 20px;
  position: absolute;
}
.form-index .action-box .left .action-title {
  font-size: 16px;
  color: #626366;
  text-indent: 40px;
}
.form-index .action-box .left .action-sub-title {
  color: #939599;
  font-size: 14px;
  text-indent: 40px;
}
.form-index .action-box .right .btn {
  background: #3A3B3B;
  border-radius: 2px;
  height: 46px;
  line-height: 46px;
  padding: 0 40px;
  text-align: centers;
  font-size: 16px;
  color: #FFFFFF;
}
.form-index .action-box .right .bz {
  color: #E8E8E8;
  font-size: 12px;
  margin-top: 6px;
  text-align: center;
}
