* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
li {
  list-style: none;
}
html,
body {
  position: relative;
  height: 100%;
  height: 100vh;
}
a,
button {
  text-decoration: none;
  color: #000000;
  display: block;
}
img {
  display: block;
}
body {
  background: #FFFFFF;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  color: #000000;
  margin: 0;
  padding: 0;
}
.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}
.clearfix::after {
  clear: both;
}
.head-bar {
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 9999;
  opacity: 1;
  transition: opacity 2s;
}
.head-bar-hidden {
  opacity: 0;
}
.user-info {
  position: absolute;
  right: 4px;
  top: 52px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.9);
  color: #555555;
  font-size: 13px;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  display: none;
}
.user-info .username {
  display: inline-block;
  padding: 0 6px;
  border-right: 1px solid #26C441;
}
.user-info .logout {
  display: inline-block;
  padding: 0 6px;
  cursor: pointer;
}
.login-list {
  position: fixed;
  top: 12vh;
  right: 30px;
  z-index: 2;
}
.login-list ul {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
}
.login-list ul li {
  width: 80px;
  text-align: center;
  padding: 10px 0;
  border-bottom: 0.5px solid #C1C1C1;
}
.login-list ul li a {
  color: #FFFFFF;
  font-size: 14px;
}
.login-list ul li:last-child {
  border: none;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none;
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
  .user-info {
    right: 0;
    top: 44px;
    border-radius: 0 0 0 4px;
    padding: 4px;
    font-size: 12px;
  }
  .login-list {
    right: 14px;
  }
  .login-list ul li {
    width: 66px;
    padding: 8px 0;
  }
  .login-list ul li a {
    font-size: 13px;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (min-width: 768px) {
  .container {
    width: 740px;
  }
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: block;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: block;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 970px;
  }
  .hidden-lg {
    display: none;
  }
  .visible-lg {
    display: block;
  }
}
@media (max-width: 767px) {
  body {
    padding-top: 44px;
  }
  .head-bar-xs {
    position: fixed;
    left: 0;
    right: 0;
    height: 44px;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 10001;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(210, 210, 210, 0.77);
  }
  .head-bar-xs > img:nth-child(1) {
    width: 100px;
    height: auto;
  }
  .head-bar-xs > img:nth-child(2) {
    width: 18px;
    height: auto;
  }
  .head-bar-xs > img:nth-child(2).bar-active {
    transform: rotate(90deg);
  }
  .head-bar-xs .side-bar {
    display: none;
    position: fixed;
    right: 0;
    left: 0;
    top: 42px;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    flex-direction: row;
    justify-content: flex-end;
  }
  .head-bar-xs .side-bar.bar-active {
    display: flex;
  }
  .head-bar-xs .side-bar .bar-list {
    padding: 20px 0 20px 15px;
    width: 60%;
    background-color: #FFFFFF;
  }
  .head-bar-xs .side-bar .bar-list > ul > li {
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #DCDCDC;
  }
  .head-bar-xs .side-bar .bar-list > ul > li > ul > li {
    padding-left: 15px;
  }
}
