.pages-product section {
  text-align: center;
  color: #FFFFFF;
}
.pages-product section .line {
  height: 10px;
  width: 100%;
  background-color: #1B1B1B;
}
.pages-product .panel > img {
  width: 100%;
  margin: 0 auto;
  height: 500px;
}
.pages-product .panel .hidden-xs {
  margin-top: 100px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  background-image: url('https://fed.dian.so/image/4e1c3c74f660d8f826d5cf34b21c17a1.jpg');
}
.pages-product .panel1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
}
.pages-product .panel1 img {
  margin-top: 100px;
}
.pages-product .panel1 .line-1 {
  width: 220px;
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #616364 50%, transparent 0);
  background-repeat: repeat-x;
  -webkit-animation: border-dance 4s linear infinite;
  animation: border-dance 4s linear infinite;
  background-size: 12px 1px;
  height: 1px;
}
@keyframes border-dance {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
.pages-product .panel1 .title {
  font-size: 44px;
  color: #54E67B;
}
.pages-product .panel-m2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.pages-product .panel-m2 img {
  margin-top: 40px;
}
.pages-product .panel-m2 .title {
  font-size: 44px;
  color: #54E67B;
}
.pages-product .panel2 {
  margin-bottom: 110px;
}
.pages-product .panel2 .title {
  font-size: 38px;
  margin-bottom: 60px;
  margin-top: 40px;
}
.pages-product .panel3 {
  margin-bottom: 120px;
}
.pages-product .panel3 .title {
  font-size: 44px;
  color: #FFFFFF;
  margin-bottom: 85px;
}
.pages-product .panel3 .hidden-xs .product-text {
  display: flex;
  justify-content: center;
}
.pages-product .panel3 .hidden-xs .product-text .item {
  font-size: 18px;
  margin-right: 55px;
}
.pages-product .panel3 .hidden-xs .product-text .item:nth-last-child(1) {
  margin-right: 0;
}
.pages-product .panel3 .hidden-xs .product-text .item::before {
  content: '';
  border: #26C441 2px solid;
  border-radius: 20px;
  margin-right: 20px;
}
.pages-product .panel3 .hidden-xs .product-list {
  display: flex;
  flex-wrap: wrap;
  width: 950px;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper {
  position: relative;
  flex: 0 0 33.3%;
  height: 427px;
  cursor: pointer;
  padding: 0 22px;
  margin-bottom: 30px;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper:hover .product-info-hover {
  display: block;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper img {
  width: 100%;
  height: 100%;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 35px;
  color: #141414;
  background: rgba(0, 0, 0, 0.75);
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .product-title {
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #BEC2CD;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .divider-line {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  background: #898D9B;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .info-wrapper {
  display: flex;
  flex-direction: column;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .info-wrapper .info-item {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 11px;
  font-size: 14px;
  text-align: left;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .info-wrapper .info-item .info-key {
  color: #BEC2CD;
  margin-bottom: 8px;
  word-break: break-all;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .info-wrapper .info-item .info-key::before {
  content: '';
  font-size: 10px;
  border: #26C441 2px solid;
  border-radius: 20px;
  margin-right: 20px;
}
.pages-product .panel3 .hidden-xs .product-list .product-item-wrapper .product-info-hover .info-wrapper .info-item .info-value {
  margin-left: 24px;
  color: #FFFFFF;
  word-break: break-all;
}
.pages-product .panel3 .hidden-xs .hot-product-container,
.pages-product .panel3 .hidden-xs .more-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pages-product .panel3 .hidden-xs .hot-product-container .name-text,
.pages-product .panel3 .hidden-xs .more-product-container .name-text {
  color: #54E67B;
  font-size: 38px;
  font-weight: 500;
  margin-top: 47px;
  margin-bottom: 25px;
}
.pages-product .panel3 .hidden-xs .more-product-container .name-text {
  color: #FFFFFF;
}
.pages-product .panel3 .visible-xs .product-text {
  display: flex;
  flex-wrap: wrap;
}
.pages-product .panel3 .visible-xs .product-text .item {
  font-size: 12px;
  width: 25%;
  flex: auto;
  margin-bottom: 15px;
}
.pages-product .panel3 .visible-xs .product-text .item::before {
  content: '';
  border: #26C441 1px solid;
  border-radius: 20px;
  margin-right: 10px;
}
.pages-product .panel3 .visible-xs .swiper-box-xs .swiper-box {
  width: 100%;
}
.pages-product .panel4 {
  margin-bottom: 160px;
}
.pages-product .panel4 .title {
  font-size: 44px;
  color: #FFFFFF;
  margin-bottom: 85px;
}
@media (max-width: 767px) {
  .pages-product .panel img {
    height: auto;
  }
  .pages-product .panel1 {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .pages-product .panel1 img {
    margin-top: 0;
    width: 90%;
  }
  .pages-product .panel-m2 .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .pages-product .panel2 .title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .pages-product .panel3 {
    margin-bottom: 100px;
  }
  .pages-product .panel3 .title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .pages-product .panel4 {
    margin-bottom: 88px;
  }
  .pages-product .panel4 .title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 40px;
  }
}
