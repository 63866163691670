.mobile-swiper-component .swiper-box-xs {
  position: relative;
}
.mobile-swiper-component .swiper-box-xs .img-xs {
  width: 70%;
  margin: 0 auto;
}
.mobile-swiper-component .swiper-box-xs .arrow-xs {
  position: absolute;
  width: 10px;
  top: 50%;
  cursor: pointer;
}
.mobile-swiper-component .swiper-box-xs .arrow-left-xs {
  left: 30px;
}
.mobile-swiper-component .swiper-box-xs .arrow-right-xs {
  right: 30px;
}
