.about-swipeableViews {
  padding: 0;
  margin: 0;
}
.pages-about {
  color: #FFFFFF;
}
.pages-about .title {
  font-size: 30px;
  margin-bottom: 68px;
}
.pages-about .sub-title {
  font-size: 24px;
  margin-bottom: 20px;
}
.pages-about .react-swipeable-view-container {
  height: 100%;
}
.pages-about .about-swipeableViews .slide-3 .container .pc-img,
.pages-about .about-swipeableViews .slide-4 .container .pc-img {
  width: 916px;
  padding-bottom: 100px;
  margin: 0 auto;
}
.pages-about .about-swipeableViews .slide-3 .container .partners-pc-img,
.pages-about .about-swipeableViews .slide-4 .container .partners-pc-img {
  width: 1065px;
  padding-bottom: 100px;
  margin: 0 auto;
}
.pages-about .head-active > a {
  color: #26C441 !important;
}
.pages-about .swiper-h-panel,
.pages-about .swiper-head-panel {
  position: relative;
}
.pages-about .swiper-h-panel .img,
.pages-about .swiper-head-panel .img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
}
.pages-about .swiper-h-panel img,
.pages-about .swiper-head-panel img {
  width: 100%;
}
.pages-about .swiper-head-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  direction: ltr;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.pages-about .swiper-head-container .swiper-head-wrapper {
  position: relative;
  width: 100%;
  transition-property: left, top, -webkit-transform;
  transition-property: transform, left, top;
  transition-property: transform, left, top, -webkit-transform;
  transition-duration: 0s;
  transform: translateZ(0);
  transition-timing-function: ease;
  box-sizing: content-box;
}
.pages-about .swiper-head-container .swiper-head-wrapper .slide-1 .swiper-h-panel,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-2 .swiper-h-panel,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-3 .swiper-h-panel,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-4 .swiper-h-panel,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-5 .swiper-h-panel {
  padding-top: 80px;
}
.pages-about .swiper-head-container .swiper-head-wrapper .slide-1 .swiper-h-panel .container,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-2 .swiper-h-panel .container,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-3 .swiper-h-panel .container,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-4 .swiper-h-panel .container,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-5 .swiper-h-panel .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pages-about .swiper-head-container .swiper-head-wrapper .slide-1 .swiper-h-panel .container .shopInfoBtn,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-2 .swiper-h-panel .container .shopInfoBtn,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-3 .swiper-h-panel .container .shopInfoBtn,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-4 .swiper-h-panel .container .shopInfoBtn,
.pages-about .swiper-head-container .swiper-head-wrapper .slide-5 .swiper-h-panel .container .shopInfoBtn {
  width: 267px;
  height: 55px;
  border: 2px solid #26C441;
  color: #26C441;
  margin-top: 90px;
  font-size: 24px;
}
.pages-about .menu-container {
  background-color: #000000;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 170px;
}
.pages-about .menu-container .container-menu-container {
  width: 800px;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 992px) {
  .pages-about .menu-container .container-menu-container {
    width: 698px;
  }
}
@media (max-width: 768px) {
  .pages-about .menu-container .container-menu-container {
    width: 100%;
  }
}
.pages-about .menu-container .container-menu-container .container-menu-container-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.pages-about .menu-container .container-menu-container .container-menu-container-ul > .menu-single {
  display: inline-block;
  flex: 1;
  height: 75px;
  line-height: 75px;
  text-align: center;
  color: #FFFFFF;
  font-size: 32px;
  cursor: pointer;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .pages-about .menu-container .container-menu-container .container-menu-container-ul > .menu-single {
    margin: 0;
    font-size: 16px;
  }
}
.pages-about .menu-container .container-menu-container .container-menu-container-ul > .menu-single.active {
  position: relative;
}
.pages-about .menu-container .container-menu-container .container-menu-container-ul > .menu-single.active::after {
  position: absolute;
  content: '';
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: 130px;
  height: 4px;
  border-radius: 2px;
  background-color: #54E67B;
}
@media (max-width: 768px) {
  .pages-about .menu-container .container-menu-container .container-menu-container-ul > .menu-single.active::after {
    bottom: 10px;
    width: 60px;
    height: 2px;
    border-radius: 1px;
    background-color: #54E67B;
  }
}
.pages-about .menu-container .tabs {
  padding: 0 0 0 0;
  font-size: 18px;
}
.pages-about .menu-container .tabs .tab {
  color: #FFFFFF;
  padding: 0 0 0 0;
  font-size: 18px;
}
.pages-about .menu-container .sub-menu {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.pages-about .menu-container .sub-menu > li {
  flex: 1;
  height: 75px;
  line-height: 75px;
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
}
.pages-about .menu-container .sub-menu > li a {
  color: #FFFFFF;
}
.pages-about .menu-container .sub-menu > li.active {
  position: relative;
}
.pages-about .menu-container .sub-menu > li.active::after {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  bottom: 17px;
  width: 80px;
  height: 4px;
  border-radius: 2px;
  background-color: #54E67B;
}
.pages-about .about {
  width: 800px;
}
.pages-about .about .title-box {
  margin-bottom: 20px;
}
.pages-about .about .title-box .title {
  font-size: 24px;
  line-height: 60px;
  height: 60px;
}
.pages-about .about .title-box .sub-title {
  color: #54E67B;
  font-size: 30px;
  line-height: 60px;
  height: 60px;
  border-bottom: 4px solid #54E67B;
  padding-bottom: 10px;
}
.pages-about .about .title-box .job-title {
  font-size: 16px;
  margin-top: 40px;
  font-weight: 500;
}
.pages-about .about .font-desc {
  font-size: 14px;
  line-height: 30px;
  text-align: justify;
}
.pages-about .about .company-introduction {
  font-size: 14px;
  line-height: 32px;
  text-align: justify;
}
.pages-about .about .company-introduction .company-introduction-primary-text {
  margin: 60px 0;
}
.pages-about .about .CEO-box {
  display: flex;
  margin-top: 140px;
  margin-bottom: 200px;
}
.pages-about .about .CEO-box .avatar {
  margin-right: 80px;
  margin-top: 90px;
}
.pages-about .about .CEO-box .avatar img {
  width: 160px;
}
.pages-about .about .CEO-box .avatar .name {
  font-size: 16px;
  line-height: 60px;
  height: 60px;
  text-align: center;
}
.pages-about .about .investment-box {
  margin-bottom: 160px;
}
.pages-about .about .investment-box img {
  width: 800px;
}
.pages-about .about .history-box {
  margin-bottom: 100px;
}
.pages-about .about .history-box .title-box {
  padding-left: 260px;
  margin-bottom: 80px;
}
.pages-about .about .history-box img {
  width: 730px;
  margin: 0 auto;
}
.pages-about .join {
  width: 800px;
  color: #000000;
}
.pages-about .join img {
  width: 100%;
}
.pages-about .join .font-desc {
  margin-bottom: 156px;
  color: #FFFFFF;
}
.pages-about .join .join-bottom {
  background-color: #FFFFFF;
  height: 300px;
  margin-bottom: 200px;
}
.pages-about .join .join-bottom .join-title {
  text-align: center;
  font-size: 28px;
  height: 140px;
  line-height: 140px;
}
.pages-about .join .join-bottom .join-desc-box {
  display: flex;
}
.pages-about .join .join-bottom .join-desc-box .left {
  width: 40%;
  text-align: center;
}
.pages-about .join .join-bottom .join-desc-box .left .btn {
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 36px;
  font-size: 13px;
  padding: 12px 24px;
}
.pages-about .join .introduce-box {
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 110px;
}
.pages-about .join .introduce-box img {
  margin-bottom: 300px;
}
.pages-about .join .introduce-box .btn {
  background-color: #FFFFFF;
  color: #000000;
  font-size: 13px;
  border: 2px solid #000000;
  border-radius: 36px;
  padding: 12px 24px;
}
@media (max-width: 767px) {
  .pages-about .title {
    font-size: 24px;
  }
  .pages-about .sub-title {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
  }
  .pages-about .swiper-wrapper .swiper-head-panel .img {
    height: 668px;
  }
  .pages-about .menu-container {
    margin-bottom: 50px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container > div {
    overflow: hidden !important;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-h-panel img {
    width: 300px;
    margin: 0 auto;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-h-panel .honor-mobile-img {
    width: 325px;
    margin: 0 auto;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .container {
    width: 100%;
    padding: 0 46px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .title-box {
    margin-bottom: 50px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .title-box .title {
    font-size: 20px;
    height: 30px;
    line-height: 30px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .title-box .sub-title {
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    border-bottom: 1px solid #54E67B;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .font-desc {
    font-size: 14px;
    color: #BBBFCA;
    line-height: 32px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .CEO-box-xs {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .CEO-box-xs .avatar img {
    margin: 0;
    width: 130px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .CEO-box-xs .avatar .name {
    margin-left: 20px;
    font-size: 14px;
    height: 60px;
    line-height: 60px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .investment-box img {
    width: 100%;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .history-box {
    margin-bottom: 0;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .history-box .title-box {
    padding-left: 0;
    margin-bottom: 50px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .history-box img {
    width: 355px;
    max-width: 100vw;
    margin-left: -30px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .company-introduction {
    font-size: 12px;
    line-height: 28px;
    text-align: justify;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .swiper-panel .about .company-introduction .company-introduction-primary-text {
    margin: 30px 0;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join {
    padding: 0 !important;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .font-desc {
    padding: 0 46px;
    font-size: 12px;
    color: #BBBFCA;
    line-height: 20px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .join-us-box .top-img {
    width: 90%;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .join-us-box .join-bottom {
    padding: 60px;
    padding-bottom: 0;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .join-us-box .join-bottom .join-title-desc {
    font-size: 12px;
    text-align: center;
    margin-bottom: 80px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .join-us-box .join-bottom .join-desc-box-xs .left {
    width: 100%;
    text-align: center;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .join-us-box .join-bottom .join-desc-box-xs .left .btn {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 36px;
    font-size: 12px;
    padding: 12px 20px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .introduce-box img {
    margin-bottom: 80px;
  }
  .pages-about .about-swipeableViews .react-swipeable-view-container .join .introduce-box .title {
    margin-bottom: 26px;
  }
}
