.swiper-container .swiper-slide .home-image {
  position: relative;
}
.swiper-container .swiper-slide .home-image .img {
  width: 100%;
}
.swiper-container .swiper-slide .home-image > div {
  width: 100%;
  position: absolute;
  bottom: 80px;
}
