.pages-cooperation .btn {
  border: 2px solid #54E67B;
  font-size: 18px;
  color: #54E67B;
  padding: 12px 30px;
  border-radius: 24px;
  cursor: pointer;
}
.pages-cooperation .title {
  font-size: 38px;
  margin-bottom: 32px;
  margin-top: 40px;
  color: #FFFFFF;
  text-align: center;
}
.pages-cooperation .sub-title {
  font-size: 20px;
  color: #BEC2CD;
  height: 36px;
  line-height: 36px;
}
.pages-cooperation .panel1 > img {
  width: 100%;
}
.pages-cooperation .panel1 .img {
  margin-top: 100px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  background-image: url('https://img3.dian.so/lhc/2022/05/18/3840w_896h_347891652869613.jpg');
}
.pages-cooperation .panel2 {
  text-align: center;
  margin-bottom: 190px;
}
.pages-cooperation .panel2 > img {
  width: 860px;
  margin: 0 auto;
  margin-top: 110px;
}
.pages-cooperation .panel3 {
  text-align: center;
  margin-bottom: 150px;
}
.pages-cooperation .panel3 > img {
  width: 600px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.pages-cooperation .panel3 .panel3-pc {
  margin-bottom: 50px;
}
.pages-cooperation .panel4 {
  text-align: center;
  margin-bottom: 160px;
}
.pages-cooperation .panel4 > img {
  width: 420px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 40px;
}
.pages-cooperation .panel5 {
  text-align: center;
  margin-bottom: 150px;
}
.pages-cooperation .panel5 > img {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 40px;
}
.pages-cooperation .panel6 {
  text-align: center;
  margin-bottom: 150px;
}
.pages-cooperation .panel6 > img {
  width: 630px;
  margin: 0 auto;
  margin-top: 40px;
}
.pages-cooperation .panel7 {
  text-align: center;
  margin-bottom: 230px;
}
.pages-cooperation .panel7 > img {
  width: 630px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 40px;
}
@media (max-width: 767px) {
  .pages-cooperation .title {
    font-size: 24px;
  }
  .pages-cooperation .btn {
    border: 1px solid #54E67B;
    font-size: 14px;
    color: #54E67B;
    padding: 12px 30px;
    border-radius: 24px;
    cursor: pointer;
  }
  .pages-cooperation .sub-title {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
  }
  .pages-cooperation .panel1 {
    position: relative;
  }
  .pages-cooperation .panel1 .btn-box {
    width: 100%;
    position: absolute;
    bottom: 80px;
    text-align: center;
  }
  .pages-cooperation .panel1 img {
    width: 100%;
    margin-top: 44px;
  }
  .pages-cooperation .panel2 img {
    width: 80%;
    margin-top: 50px;
  }
  .pages-cooperation .panel3 img {
    margin-top: 26px;
  }
  .pages-cooperation .panel3 .panel3-mini {
    margin-top: 26px;
    margin-bottom: 50px;
  }
  .pages-cooperation .panel4 {
    margin-bottom: 100px;
  }
  .pages-cooperation .panel4 img {
    width: 90%;
    margin-top: 52px;
  }
  .pages-cooperation .panel5 {
    margin-bottom: 85px;
  }
  .pages-cooperation .panel5 img {
    width: 100%;
    margin-top: 52px;
  }
  .pages-cooperation .panel6 {
    margin-bottom: 90px;
  }
  .pages-cooperation .panel6 img {
    width: 100%;
    margin-top: 52px;
  }
  .pages-cooperation .panel7 {
    margin-bottom: 90px;
  }
  .pages-cooperation .panel7 img {
    width: 100%;
    margin-top: 52px;
  }
}
