.merchant-form-modal-contrl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.merchant-form-modal-contrl .merchant-form-head {
  position: relative;
  padding: 38px 165px;
  background: #FFFFFF;
}
.merchant-form-modal-contrl .merchant-form-content {
  padding-left: 120px;
  padding-right: 120px;
  background: #FFFFFF;
}
.merchant-form-modal-contrl .modal-close {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  margin: 0 auto;
  top: 18px;
  right: 50px;
  cursor: pointer;
}
.merchant-form-modal-contrl .modal-close::before,
.merchant-form-modal-contrl .modal-close::after {
  position: absolute;
  content: ' ';
  background-color: #D8D8D8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 10px;
}
.merchant-form-modal-contrl .modal-close::before {
  transform: rotate(45deg);
}
.merchant-form-modal-contrl .modal-close::after {
  transform: rotate(-45deg);
}
.merchant-form-modal-contrl .merchant-form-index {
  margin: auto;
  width: 800px;
  height: 500px;
  background: #FFFFFF;
}
.merchant-form-modal-contrl .merchant-form-index .MuiFormLabel-asterisk {
  color: #FF951C;
}
.merchant-form-modal-contrl .merchant-form-index .address-cascader {
  flex: 1;
}
.merchant-form-modal-contrl .merchant-form-index .address-cascader .ant-select-selector {
  border: 0;
}
.merchant-form-modal-contrl .merchant-form-index .MuiGrid-item:nth-child(4) .MuiInput-underline::before {
  border-width: 0;
}
.merchant-form-modal-contrl .merchant-form-index .MuiGrid-item:nth-child(4) .MuiInputBase-inputMarginDense {
  padding-top: 9px;
}
.merchant-form-modal-contrl .merchant-form-index .MuiFormControlLabel-labelPlacementStart {
  border-bottom: 1px solid #D8D8D8;
  margin-top: 20px;
  width: 95%;
}
.merchant-form-modal-contrl .merchant-form-index .MuiInputLabel-asterisk {
  color: #FF951C;
}
.merchant-form-modal-contrl .merchant-form-index .MuiFormLabel-root {
  color: #A1A1A1;
  font-size: 12px;
}
.merchant-form-modal-contrl .merchant-form-index .MuiInput-underline::before {
  border-bottom: 1px solid #D8D8D8;
}
.merchant-form-modal-contrl .merchant-form-index .MuiTextField-root {
  width: 90%;
}
.merchant-form-modal-contrl .merchant-form-index .merchant-form-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3A3B3B;
  font-size: 20px;
  font-weight: bolder;
}
.merchant-form-modal-contrl .merchant-form-index .tips {
  font-size: 12px;
  color: #A1A1A1;
  line-height: 17px;
  text-align: center;
  padding-top: 50px;
}
.merchant-form-modal-contrl .merchant-form-index .btn {
  width: 263px;
  height: 45px;
  background: #3A3B3B;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 20px auto 0;
  color: #FFFFFF;
  cursor: pointer;
  border: 0;
}
.merchant-form-modal-contrl .merchant-form-index .loading {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.merchant-form-modal-contrl .merchant-form-index ::-webkit-input-placeholder {
  font-size: 12px;
  color: #D4D4D4;
}
