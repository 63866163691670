.pages-hot {
  padding: 0 0 40px;
}
.pages-hot hr {
  border: 1px solid #EAEAEA;
  width: 1200px;
  margin: 0 auto;
}
.pages-hot .top-img-container .hidden-xs {
  margin-top: 100px;
}
.pages-hot .top-img-container img {
  width: 100%;
}
.pages-hot .swiper-box {
  position: relative;
  width: 950px;
  margin: 0 auto;
}
.pages-hot .swiper-box .arrow {
  position: absolute;
  width: 14px;
  top: 50%;
  cursor: pointer;
}
.pages-hot .swiper-box .arrow-left {
  left: -40px;
}
.pages-hot .swiper-box .arrow-right {
  right: -40px;
}
.pages-hot .swiper-box .card-content {
  padding: 40px;
  width: 100%;
  background-color: #141414;
  color: #FFFFFF;
}
.pages-hot .swiper-box .card-content img {
  width: 410px;
}
.pages-hot .swiper-box .card-content .banner-desc .banner-title {
  font-size: 24px;
  margin-bottom: 40px;
}
.pages-hot .swiper-box .card-content .banner-desc .banner-content {
  font-size: 14px;
  color: #BEC2CD;
  margin-bottom: 50px;
  line-height: 25px;
}
.pages-hot .swiper-box .card-content .banner-desc .banner-updateTime {
  font-size: 12px;
  color: #BEC2CD;
}
.pages-hot .swiper-box .card-content .banner-desc .banner-source {
  font-size: 12px;
  background-color: #313131;
  color: #BEC2CD;
  border-radius: 10px;
  padding: 2px 15px;
  float: right;
}
.pages-hot .container-my-pagination {
  position: absolute;
  left: 0;
  bottom: 85px;
  width: 698px;
  height: 4px;
}
.pages-hot .my-pagination {
  position: absolute;
  left: 50%;
  width: 698px;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
}
.pages-hot .my-pagination > ul {
  margin: 0 auto;
}
.pages-hot .my-pagination > ul > li {
  display: inline-block;
  width: 50px;
  height: 4px;
  background-color: #CCCCCC;
  opacity: 0.4;
  margin: 0 5px;
  padding: 2px;
}
.pages-hot .my-pagination > ul > li.active {
  background-color: #23BC39 !important;
  opacity: 1;
}
.pages-hot .pages-hot-menu {
  color: #FFFFFF;
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.pages-hot .pages-hot-menu > ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.pages-hot .pages-hot-menu > ul > li {
  margin: 0 10px;
  display: inline-block;
  padding-bottom: 9px;
  cursor: pointer;
}
.pages-hot .pages-hot-menu > ul .active {
  padding-bottom: 5px;
  border-bottom: 4px solid #54E67B;
}
.pages-hot .hot-nav-container {
  padding-top: 11px;
  margin: 0 auto;
  margin-top: 140px;
  width: 1100px;
}
.pages-hot .hot-nav-container .card-content {
  padding: 30px;
  width: 100%;
  background-color: #141414;
  color: #FFFFFF;
  margin-bottom: 42px;
  border-radius: 10px;
}
.pages-hot .hot-nav-container .card-content .img {
  background-size: cover;
  width: 271px;
  height: 159px;
  margin: 0 24px0 0;
}
.pages-hot .hot-nav-container .card-content .banner-desc .banner-title {
  font-size: 24px;
  margin-bottom: 12px;
}
.pages-hot .hot-nav-container .card-content .banner-desc .banner-content {
  font-size: 14px;
  color: #BEC2CD;
  margin-bottom: 50px;
  line-height: 25px;
}
.pages-hot .hot-nav-container .card-content .banner-desc .banner-updateTime {
  font-size: 12px;
  color: #BEC2CD;
}
.pages-hot .hot-nav-container .card-content .banner-desc .banner-source {
  font-size: 12px;
  background-color: #313131;
  color: #BEC2CD;
  border-radius: 10px;
  padding: 2px 15px;
  float: right;
}
@media (max-width: 767px) {
  .pages-hot .hot-nav-container .simple-hot-wrapper {
    padding: 0;
  }
}
.pages-hot .hot-nav-container .simple-hot {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  padding: 34px 0;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-img {
  background-size: cover;
  width: 271px;
  height: 159px;
  margin: 0 24px 0 0;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-content {
  width: 75%;
  overflow: hidden;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-content .title {
  font-size: 24px;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-content .source-time {
  color: #999999;
  padding: 7px 0 6px 0;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-content .source-time span {
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: left;
}
.pages-hot .hot-nav-container .simple-hot .simple-hot-content .section-content {
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: justify;
  line-height: 32px;
  height: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .pages-hot .top-hr {
    width: 770px !important;
    margin: 0 auto;
  }
  .pages-hot .banner-container {
    width: 698px;
    margin: 55px auto;
    height: 250px;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews {
    width: 698px !important;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews .hot-swiper {
    width: 698px;
    margin: 0 auto;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews .hot-swiper .swiper-img {
    width: 422px;
    height: 250px;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews .hot-swiper .swiper-img-right {
    right: 0;
    width: 35%;
    height: 250px;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews .hot-swiper .swiper-img-right .swiper-img-title {
    margin: 0;
    font-size: 20px;
  }
  .pages-hot .page-hot-AutoPlaySwipeableViews .hot-swiper .swiper-img-right .swiper-img-content {
    margin-bottom: 0;
    font-size: 10px;
  }
  .pages-hot .about-swipeableViews {
    width: 96%;
    margin: 0 auto;
  }
  .pages-hot .about-swipeableViews .hot-nav-container .simple-hot .simple-hot-img {
    background-position: center;
  }
  .pages-hot .about-swipeableViews .hot-nav-container .simple-hot .simple-hot-content {
    height: 159px;
  }
  .pages-hot .about-swipeableViews .hot-nav-container .simple-hot .simple-hot-content .title {
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    width: 100%;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pages-hot .container-my-pagination {
    bottom: 20px;
    width: 422px;
  }
}
@media (min-width: 1920px) {
  .pages-hot .top-hr {
    width: 1200px !important;
    margin: 0 auto;
  }
  .pages-hot .my-pagination > ul > li {
    margin: 0.3em;
  }
  .pages-hot .hot-nav-container hr {
    border: 1px solid #EAEAEA;
    width: 90%;
    margin: 0 auto;
  }
  .pages-hot .hot-nav-container .simple-hot .simple-hot-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .pages-hot .hot-nav-container .simple-hot .simple-hot-content .title {
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    width: 700px;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 767px) {
  .pages-hot .pages-hot-menu {
    width: 90%;
  }
  .pages-hot .pages-hot-menu > ul > li {
    font-size: 20px;
  }
  .pages-hot .pages-hot-menu > ul > .active {
    border-bottom: 2px solid #54E67B;
  }
  .pages-hot .swiper-box {
    width: 100%;
    margin-bottom: 40px;
  }
  .pages-hot .swiper-box .card-content-xs {
    width: 100vw;
  }
  .pages-hot .swiper-box .card-content-xs img {
    width: 300px;
    margin: 0 auto;
  }
  .pages-hot .swiper-box .card-content-xs .title {
    color: #FFFFFF;
    width: 70%;
    margin: 0 auto;
    margin-top: -40px;
  }
  .pages-hot .hot-nav-container {
    margin-top: 0;
    width: 100%;
  }
  .pages-hot .hot-nav-container .card-content-xs {
    background-color: transparent;
    padding: 25px 0;
    box-sizing: border-box;
    margin: 0 30px;
    border-bottom: 1px solid #373737;
  }
  .pages-hot .hot-nav-container .card-content-xs .img {
    background-size: cover;
    height: 67px;
    width: 115px;
  }
  .pages-hot .hot-nav-container .card-content-xs .banner-desc {
    color: #FFFFFF;
  }
  .pages-hot .hot-nav-container .card-content-xs .banner-desc .banner-title {
    font-size: 12px;
  }
  .pages-hot .hot-nav-container .card-content-xs .banner-desc .banner-updateTime {
    font-size: 10px;
    color: #BEC2CD;
  }
  .pages-hot .hot-nav-container .card-content-xs .banner-desc .banner-source {
    font-size: 10px;
    background-color: #313131;
    color: #BEC2CD;
    border-radius: 10px;
    padding: 2px 15px;
    float: right;
  }
}
