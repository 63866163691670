.ad-form-modal-contrl {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.ad-form-modal-contrl .ad-form-head {
  position: relative;
  padding: 38px 165px;
  background: #FFFFFF;
}
.ad-form-modal-contrl .ad-form-content {
  padding-left: 165px;
  padding-right: 165px;
  background: #FFFFFF;
}
.ad-form-modal-contrl .modal-close {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  margin: 0 auto;
  top: 18px;
  right: 50px;
  cursor: pointer;
}
.ad-form-modal-contrl .modal-close::before,
.ad-form-modal-contrl .modal-close::after {
  position: absolute;
  content: ' ';
  background-color: #D8D8D8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 10px;
}
.ad-form-modal-contrl .modal-close::before {
  transform: rotate(45deg);
}
.ad-form-modal-contrl .modal-close::after {
  transform: rotate(-45deg);
}
.ad-form-modal-contrl .ad-form-index {
  margin: auto;
  width: 800px;
  height: 500px;
  background: #FFFFFF;
}
.ad-form-modal-contrl .ad-form-index .MuiInputLabel-asterisk {
  color: #FF951C;
}
.ad-form-modal-contrl .ad-form-index .MuiFormLabel-root {
  color: #A1A1A1;
  font-size: 12px;
}
.ad-form-modal-contrl .ad-form-index .MuiInput-underline::before {
  border-bottom: 1px solid #D8D8D8;
}
.ad-form-modal-contrl .ad-form-index .MuiTextField-root {
  width: 90%;
  margin-bottom: 30px;
}
.ad-form-modal-contrl .ad-form-index .ad-form-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3A3B3B;
  font-size: 20px;
  font-weight: bolder;
}
.ad-form-modal-contrl .ad-form-index .btn {
  width: 263px;
  height: 45px;
  background: #3A3B3B;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 70px auto 0;
  color: #FFFFFF;
  cursor: pointer;
  border: 0;
}
.ad-form-modal-contrl .ad-form-index .loading {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.ad-form-modal-contrl .MuiFormControlLabel-labelPlacementStart {
  border-bottom: 1px solid #D8D8D8;
  margin-top: 20px;
  width: 92%;
  margin-left: 0;
}
.ad-form-modal-contrl .MuiFormControlLabel-labelPlacementStart .ant-select {
  width: 100%;
}
.ad-form-modal-contrl .MuiFormControlLabel-labelPlacementStart .ant-select-selector {
  border: 0 !important;
  padding: 0 !important;
}
