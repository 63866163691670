.index-page {
  margin-top: 100px;
}
.index-page .btn-box {
  margin-top: 65px;
  text-align: center;
}
.index-page .btn-box .btn {
  border: 2px solid #54E67B;
  font-size: 18px;
  color: #54E67B;
  padding: 12px 30px;
  border-radius: 24px;
  cursor: pointer;
}
.index-page .map {
  background-image: url('http://img3.dian.so/lhc/2022/08/31/1920w_701h_524921661916785.png');
  background-size: cover;
  background-position: center;
  height: 700px;
  margin-top: 70px;
  padding-top: 12px;
}
.index-page .map .map-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: #FFFFFF;
}
.index-page .map .map-header .map-header-title {
  font-size: 45px;
  line-height: 63px;
  margin-bottom: 20px;
}
.index-page .map .map-header .map-header-text {
  font-size: 18px;
  margin-bottom: 10px;
}
.index-page .join {
  margin-top: 85px;
}
.index-page .join .img-1 {
  width: 578px;
  height: 603px;
  margin: 0 auto;
}
.index-page .join .img-2-box {
  text-align: center;
}
.index-page .join .img-2-box .img-2 {
  display: inline-block;
}
.index-page .advertising {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 220px;
}
.index-page .advertising .img-cover {
  background-size: cover;
  background-position: center;
}
.index-page .advertising .img-1 {
  width: 100%;
  height: 260px;
  background-image: url('https://img3.dian.so/lhc/2021/11/02/3840w_520h_673561635824794.jpeg');
}
.index-page .advertising .img-4 {
  margin-top: 66px;
  margin-bottom: 185px;
}
.index-page .advertising .img-5 {
  margin-top: 66px;
  margin-bottom: 100px;
}
.index-page .advertising .img-6 {
  width: 560px;
  margin-top: 58px;
  margin-bottom: 38px;
}
.index-page .advertising .img-7 {
  width: 670px;
  margin-bottom: 100px;
}
.index-page .advertising .img-8 {
  margin-top: 66px;
  margin-bottom: 100px;
}
.index-page .advertising .title {
  text-align: center;
}
.index-page .advertising .title .theme {
  font-size: 44px;
  color: #FFFFFF;
}
.index-page .advertising .title .desc {
  font-size: 18px;
  margin-top: 35px;
  color: #BBBFCA;
}
@media (max-width: 767px) {
  body {
    padding-top: 0;
  }
  .index-page {
    margin-top: 44px;
  }
  .index-page .btn-box .btn {
    border: 1px solid #54E67B;
    font-size: 14px;
    color: #54E67B;
    padding: 12px 24px;
    border-radius: 24px;
    cursor: pointer;
  }
  .index-page .map {
    height: 500px;
    background-image: url('https://img3.dian.so/lhc/2022/09/16/750w_974h_1E9B31663296326.jpg');
    background-size: cover;
  }
  .index-page .map-xs {
    color: #FFFFFF;
    text-align: center;
  }
  .index-page .map-xs .map-header-xs {
    padding-top: 22px;
  }
  .index-page .map-xs .map-header-xs .map-header-title-xs {
    font-size: 26px;
  }
  .index-page .map-xs .map-header-xs .map-header-text-xs {
    padding-top: 8px;
    font-size: 13px;
  }
  .index-page .advertising .img-1 {
    height: 120px;
  }
  .index-page .advertising .img-2 {
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;
  }
  .index-page .advertising .img-3 {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .index-page .advertising .img-4 {
    margin-bottom: 100px;
  }
  .index-page .advertising .img-6 {
    width: 84%;
  }
  .index-page .advertising .img-7 {
    width: 100%;
  }
  .index-page .advertising .img-8 {
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .index-page .advertising .title {
    text-align: center;
  }
  .index-page .advertising .title .theme {
    font-size: 24px;
  }
  .index-page .advertising .title .desc {
    margin-top: 10px;
    font-size: 12px;
  }
}
