.panel .panel1 {
  display: flex;
  justify-content: center;
  padding-top: 82px;
}
.panel .panel1 .box {
  display: flex;
  flex-direction: column;
}
.panel .panel1 .box .font-head {
  font-size: 40px;
  font-weight: bold;
  color: #191818;
  letter-spacing: 0.92px;
}
.panel .panel1 .box .form {
  height: 527px;
  position: relative;
  border: 1px solid #DCDEE3;
  border-radius: 8px;
  margin-top: -110px;
}
.panel .panel1 .box .form .form-top {
  display: flex;
  padding: 24px 47px 24px 95px;
}
.panel .panel1 .box .form .form-top .form-item:first-child {
  margin-right: 28px;
  margin-left: -39px;
}
.panel .panel1 .box .form .form-top .form-item .box {
  margin-top: 11px;
}
.panel .panel1 .box .form .form-top .form-item .box:nth-child(1) .must_fill::after {
  content: '*';
  color: red;
}
.panel .panel1 .box .form .form-top .form-item .box:nth-child(2) .must_fill::after {
  content: '*';
  color: red;
}
.panel .panel1 .box .form .form-top .form-item .box .must_fill {
  display: block;
  font-size: 14px;
  color: #96989C;
  letter-spacing: 0;
}
.panel .panel1 .box .form .form-top .form-item .box .item_input {
  width: 266px;
  height: 43px;
  text-indent: 14px;
  border: 1px solid #D7D9DD;
  border-radius: 6px;
  background: #FFFFFF;
  margin-top: 3px;
}
.panel .panel1 .box .form .submitBtn {
  width: 451px;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0.95px;
  cursor: pointer;
  text-align: center;
  margin: 6px auto 0;
  padding: 18px 88px;
  background: #26C441;
  border-radius: 34.5px;
}
.panel .panel1 .box .form .form-bottom {
  width: 100%;
  font-size: 12px;
  color: #D0D0D0;
  letter-spacing: 0.67px;
  margin-top: 15px;
  text-align: center;
}
.panel .panel1 .box .form .form-bottom-phone {
  display: flex;
  justify-content: space-between;
  background: #FBFBFB;
  border: 1px solid #DCDEE3;
  border-radius: 9px;
  margin-top: 22px;
  padding: 19px 63px;
}
.panel .panel1 .box .form .form-bottom-phone .phone {
  font-size: 22px;
  color: #5D5E61;
  letter-spacing: 0;
}
.panel .panel1 .box .form .form-bottom-phone .phone .icon-bohao {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  display: inline-block;
  background-image: url('https://img3.dian.so/lhc/2021/10/19/56w_56h_9FB171634628474.png');
  background-size: cover;
  background-position: center;
  margin-right: 18px;
}
.panel .panel1 .box .form .form-bottom-phone .time {
  font-size: 14px;
  color: #939599;
  letter-spacing: 0;
  line-height: 25px;
}
.panel .panel1-xs {
  padding: 50px 57px;
  height: 100%;
  background-image: url('https://img3.dian.so/lhc/2021/10/19/750w_1318h_BEB971634629507.jpg');
  background-size: 100% 100%;
  background-position: center;
}
.panel .panel1-xs .box .form {
  min-width: 408px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.panel .panel1-xs .box .form .place-input {
  margin: -8px auto -8px;
  text-align: center;
}
.panel .panel1-xs .box .form .place-input .place {
  width: 90px;
  position: relative;
  top: 38px;
  left: 16px;
}
.panel .panel1-xs .box .form .place-input .item_input {
  width: 300px;
  height: 42px;
  border: none;
  background: #F0F0F0;
  border-radius: 2px;
  text-indent: 13px;
  font-size: 10px;
  letter-spacing: 0.68px;
  margin: 0 auto;
}
.panel .panel1-xs .box .form .place-input .item_input:nth-child(5) {
  margin-top: 16px;
}
.panel .panel1-xs .box .form .place-input .item_input::-webkit-input-placeholder {
  font-size: 12px;
  color: red;
  letter-spacing: 0;
}
.panel .panel1-xs .box .form .place-input .item_input::-moz-placeholder {
  font-size: 12px;
  color: #B8B8B8;
  letter-spacing: 0;
}
.panel .panel1-xs .box .form .place-input .item_input::-ms-input-placeholder {
  font-size: 12px;
  color: #B8B8B8;
  letter-spacing: 0;
}
.panel .panel1-xs .box .form .submitBtn {
  border-radius: 25px;
  padding: 16px 27px;
  background: #26C441;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.95px;
  cursor: pointer;
  margin: 28px auto 10px;
  text-align: center;
}
.panel .panel1-xs .box .form .form-bottom {
  width: 100%;
  font-size: 8px;
  color: #e8e8e8;
  letter-spacing: 0.44px;
  text-align: center;
}
.panel .panel1-xs .box .form .form-bottom-phone {
  text-align: center;
}
.panel .panel1-xs .box .form .form-bottom-phone .phone {
  font-size: 14px;
  color: #5D5E61;
  letter-spacing: 0;
}
.panel .panel1-xs .box .form .form-bottom-phone .phone .icon-bohao {
  width: 17px;
  height: 17px;
  vertical-align: middle;
  display: inline-block;
  background-image: url('https://img3.dian.so/lhc/2021/10/19/34w_34h_82C771634630143.png');
  background-size: cover;
  background-position: center;
  margin-right: 11px;
}
.panel .panel1-xs .box .form .form-bottom-phone .time {
  font-size: 10px;
  color: #939599;
  letter-spacing: 0;
  margin-top: 3px;
}
