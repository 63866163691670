.pages-merchant .btn {
  border: 2px solid #54E67B;
  font-size: 18px;
  color: #54E67B;
  padding: 12px 30px;
  border-radius: 24px;
  cursor: pointer;
}
.pages-merchant .title {
  font-size: 38px;
  margin-bottom: 60px;
  margin-top: 40px;
  color: #FFFFFF;
  text-align: center;
}
.pages-merchant .panel1 > img {
  width: 100%;
}
.pages-merchant .panel1 .hidden-xs {
  margin-top: 100px;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  background-image: url('https://fed.dian.so/image/9d9d775a4d253258b32eb5d88e5b7150.png');
}
.pages-merchant .panel2 > img {
  width: 850px;
  margin: 0 auto;
  margin-bottom: 180px;
}
.pages-merchant .panel3 .img {
  width: 100%;
  height: 260px;
  background-size: cover;
  background-position: center;
  background-image: url('https://img3.dian.so/lhc/2021/11/08/3840w_520h_0262B1636340770.jpg');
  margin: 0 auto;
  margin-bottom: 180px;
}
.pages-merchant .panel4 .merchant-card-box {
  display: flex;
  flex-wrap: wrap;
}
.pages-merchant .panel4 .merchant-card-box .merchant-card-item {
  flex: 0 1 33.3%;
  text-align: center;
  margin-bottom: 70px;
}
.pages-merchant .panel4 .merchant-card-box .merchant-card-item .merchant-title {
  font-size: 24px;
  color: #54E67B;
  margin-bottom: 25px;
}
.pages-merchant .panel4 .merchant-card-box .merchant-card-item .sub-title {
  font-size: 14px;
  color: #FFFFFF;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}
.pages-merchant .panel4 .merchant-card-box .merchant-card-item .img {
  margin: 0 auto;
  margin-top: 28px;
  width: 300px;
  height: 155px;
}
.pages-merchant .panel4 .merchant-card-box-xs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  justify-content: center;
}
.pages-merchant .panel4 .merchant-card-box-xs .merchant-card-item-xs {
  width: 44%;
  margin: 0 5px;
}
.pages-merchant .panel4 .merchant-card-box-xs .merchant-card-item-xs .merchant-title {
  font-size: 16px;
  color: #54E67B;
  margin: 25px 0;
}
.pages-merchant .panel4 .merchant-card-box-xs .merchant-card-item-xs .mImg {
  width: 100%;
  margin-bottom: 14px;
}
.pages-merchant .panel4 .merchant-card-box-xs .merchant-card-item-xs .sub-title {
  color: #FFFFFF;
  font-size: 10px;
}
.pages-merchant .panel5 {
  text-align: center;
}
.pages-merchant .panel5 .hidden-xs {
  margin: 0 auto;
  margin-bottom: 75px;
}
.pages-merchant .panel6 {
  margin-bottom: 230px;
}
.pages-merchant .panel6 .arrow {
  position: absolute;
  width: 14px;
  top: 50%;
  cursor: pointer;
}
.pages-merchant .panel6 .arrow-left {
  left: -40px;
}
.pages-merchant .panel6 .arrow-right {
  right: -40px;
}
.pages-merchant .panel6 .title {
  margin-top: 150px;
}
.pages-merchant .panel6 .img {
  width: 660px;
}
.pages-merchant .panel7 {
  text-align: center;
  color: #54E67B;
  margin-bottom: 150px;
}
.pages-merchant .panel7 .title {
  font-size: 44px;
  margin-bottom: 15px;
}
.pages-merchant .panel7 .subtitle {
  font-size: 20px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .pages-merchant .title {
    font-size: 24px;
  }
  .pages-merchant .btn {
    border: 1px solid #54E67B;
    font-size: 14px;
  }
  .pages-merchant .panel1 {
    position: relative;
  }
  .pages-merchant .panel1 .btn-box {
    width: 100%;
    position: absolute;
    bottom: 80px;
    text-align: center;
  }
  .pages-merchant .panel2 img {
    width: 90%;
    margin-bottom: 90px;
  }
  .pages-merchant .panel3 .img {
    height: 120px;
  }
  .pages-merchant .panel5 .visible-xs {
    margin: 0 auto;
    margin-bottom: 65px;
    width: 90%;
  }
  .pages-merchant .panel6 {
    margin-bottom: 85px;
  }
}
